<template>
  <div class="container-fluid">
    <div class="box-item">
      <div class="box-img"></div>
      <div class="box-info">
        <div>联系我们</div>
        <div style="width: 500px">
          <div class="info-line">
            <div>
              <label class="control-label">您的名字</label>
              <div>
                <input v-model="emailBody.Name" type="text" class="form-control" name="YourName" @blur="checkUserName"
                       :class="{ error : nameerror}">
                <span style="color:red"><span>{{ nameerror }}</span></span>
              </div>
            </div>
            <div>
              <label class="control-label">您的公司</label>
              <div>
                <input v-model="emailBody.CompanyName" type="text" class="form-control" name="YourCompany"
                       @blur="checkCompanyName" :class="{ error : companyerror}">
                <span style="color:red"><span>{{ companyerror }}</span></span>
              </div>
            </div>
          </div>
          <div class="info-line">
            <div>
              <label class="control-label">您的电话</label>
              <div>
                <input v-model="emailBody.Phone" type="text" class="form-control" name="YourPhone" @blur="checkPhone"
                       :class="{ error : phoneerror}">
                <span style="color:red"><span>{{ phoneerror }}</span></span>
              </div>
            </div>
            <div>
              <label class="control-label">您的邮件</label>
              <div>
                <input v-model="emailBody.Email" type="text" class="form-control" name="YourEmail" @blur="checkEmail"
                       :class="{ error : emailerror}">
                <span style="color:red"><span>{{ emailerror }}</span></span>
              </div>
            </div>
          </div>
          <div class="info-line info-line-text">
            <div>
              <label class="control-label">您的留言</label>
              <div>
                <b-form-textarea style="width: 500px;height: 151px" v-model="emailBody.Content" class="form-control"
                                 name="YourMessage" @blur="checkReq" :class="{ error : helperror}"/>
                <span style="color:red"><span>{{ helperror }}</span></span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <button class="btn" :class="{ isBtn : isBtnDisable }" :disabled="!isBtnDisable" @click.prevent="sendMail">发送</button>
          <button :disabled="false" class="btn" @click="cancelMail">取消</button>
        </div>
      </div>
    </div>

    <common-dialog id="contactemailDialog" :message="dialogmsg"/>
  </div>
</template>

<script>
import CommonDialog from '@/components/cn/CommonDialog'

export default {
  name: 'CnGetInTouch',
  components: {
    CommonDialog
  },
  data () {
    return {
      emailBody: {
        Name: '',
        CompanyName: '',
        Phone: '',
        Email: '',
        Content: ''
      },
      nameerror: '',
      companyerror: '',
      phoneerror: '',
      emailerror: '',
      helperror: '',
      dialogmsg: '',
      notEmptySet: new Set([]),
      isBtnDisable: false
    }
  },
  computed: {
    isCheck () {
      return this.checkUserName() && this.checkCompanyName() && this.checkPhone() && this.checkEmail() && this.checkReq()
    }
  },
  mounted () {},
  methods: {
    sendMail () {
      if (this.isCheck) {
        this.$store.dispatch('SetLoadingStatus', true)
        this.$store.dispatch('SendMail', this.emailBody).then(() => {
          this.dialogmsg = 'contactemailsendok'
          this.$store.dispatch('SetLoadingStatus', false)
          this.$bvModal.show('contactemailDialog')
          this.emailBody = {}
        }).catch(() => {
          this.$store.dispatch('SetLoadingStatus', false)
        })
      }
    },
    checkUserName () {
      if (!this.emailBody.Name || !this.emailBody.Name.replace(/\s*/g, '')) {
        this.notEmptySet.delete('username')
        this.nameerror = '请输入您的名字'
        this.isBtnDisable = this.isDisabled()
        return false
      } else {
        this.notEmptySet.add('username')
        this.nameerror = ''
        this.isBtnDisable = this.isDisabled()
        return true
      }
    },
    checkCompanyName () {
      if (!this.emailBody.CompanyName || !this.emailBody.CompanyName.replace(/\s*/g, '')) {
        this.notEmptySet.delete('company')
        this.companyerror = '请输入您的公司'
        this.isBtnDisable = this.isDisabled()
        return false
      } else {
        this.notEmptySet.add('company')
        this.companyerror = ''
        this.isBtnDisable = this.isDisabled()
        return true
      }
    },
    checkPhone () {
      if (!this.emailBody.Phone || !this.emailBody.Phone.replace(/\s*/g, '')) {
        this.notEmptySet.delete('phone')
        this.phoneerror = '请输入您的电话'
        this.isBtnDisable = this.isDisabled()
        return false
      } else if (!/\d+/.test(this.emailBody.Phone)) {
        this.notEmptySet.delete('phone')
        this.phoneerror = '只允许使用数字'
        this.isBtnDisable = this.isDisabled()
        return false
      } else {
        this.notEmptySet.add('phone')
        this.phoneerror = ''
        this.isBtnDisable = this.isDisabled()
        return true
      }
    },
    checkEmail () {
      if (!this.emailBody.Email || !this.emailBody.Email.replace(/\s*/g, '')) {
        this.notEmptySet.delete('email')
        this.emailerror = '请输入您的邮件'
        this.isBtnDisable = this.isDisabled()
        return false
      } else if (!/(\S)+[@]{1}(\S)+[.]{1}(\w)+/.test(this.emailBody.Email)) {
        this.notEmptySet.delete('email')
        this.emailerror = '邮件格式错误'
        this.isBtnDisable = this.isDisabled()
        return false
      } else {
        this.notEmptySet.add('email')
        this.emailerror = ''
        this.isBtnDisable = this.isDisabled()
        return true
      }
    },
    checkReq () {
      if (!this.emailBody.Content || !this.emailBody.Content.replace(/\s*/g, '')) {
        this.notEmptySet.delete('request')
        this.helperror = '请输入您的留言'
        this.isBtnDisable = this.isDisabled()
        return false
      } else {
        this.notEmptySet.add('request')
        this.helperror = ''
        this.isBtnDisable = this.isDisabled()
        return true
      }
    },
    cancelMail () {
      this.$router.push('/cn/BTCInfo')
    },
    handleFollowUs (url) {
      window.open(url)
    },
    isDisabled () {
      return this.notEmptySet.size === 5
    }
  }
}
</script>

<style lang="scss" scoped>
$cell-height: 712px;

.container-fluid {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  color: #535353;
}

.box-item {
  margin: 20px 0;
  width: 1200px;
  height: $cell-height;
  background-color: #ffffff;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (min-width: 2048px) {
  .box-item {
    margin: 60px 0;
  }
}

.box-img {
  width: 525px;
  height: $cell-height;
  background-position: 50% 50%;
  background-size: 100%;
  background-image: url(../../../../assets/image/cn/contact-us.png);
  background-repeat: no-repeat;
}

.box-info {
  flex: 1;
  height: $cell-height;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.box-info > div:first-child {
  font-size: 30px;
  font-weight: bold;
  color: #4F5157;
  margin: 80px 0 30px 0;
}

.box-info > div:last-child {
  width: 500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
}

.box-info > div:last-child > button {
  width: 226px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.box-info > div:last-child > button:first-child {
  background-color: #A5C8E6;
  color: #ffffff;
}

.box-info > div:last-child > button:last-child {
  background-color: #E6EAED;
  color: #999999;
}

.info-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

.info-line > div{
  height: 90px;
}

.info-line-text > div{
  height: 180px!important;
}

.control-label {
  font-size: 14px;
  color: #666666;
  margin-bottom: 2px;
}

.form-control {
  background-color: #EDF1F4;
  width: 226px;
  height: 35px;
  border-radius: 8px;
  border: 1px solid #EDF1F4;
}
.isBtn{
  background-color: rgb(66,133,244)!important;
}
</style>
